export const SHIPPER_URL = '/shipper/'
export const PRICE_MONITOR_URL = '/monitor/price/'
export const CHAT_URL = '/chat/'
export const CUSTOM_REPORT_URL = '/custom/report/'
export const REPORTS_URL = '/reports/'

export const getAggregateReportURL = (shipperId: string) => `${SHIPPER_URL}${shipperId}/report/`

export const getAggregateReportDetailsURL = (shipperId: string, detailsKey: string) =>
  `${getAggregateReportURL(shipperId)}details/${detailsKey}/`

export const getLoadsmartReportURL = (shipperId: string) =>
  `${getAggregateReportURL(shipperId)}carrier/loadsmart/`

export const getLoadsmartReportDetailsURL = (shipperId: string, detailsKey: string) =>
  `${getAggregateReportURL(shipperId)}carrier/loadsmart/details/${detailsKey}/`

export const getPriceMonitorURL = (shipperId: string) => `${PRICE_MONITOR_URL}${shipperId}/`

export const getChatURL = (shipperId: string) => `${CHAT_URL}${shipperId}/`

export const getCustomReportURL = (shipperId: string) => `${CUSTOM_REPORT_URL}${shipperId}/`

export const getReportsURL = (shipperId: string) => `${REPORTS_URL}${shipperId}/`
