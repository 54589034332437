import { useCallback } from 'react'
import { useRouter } from 'next/router'

const useQueryParams = () => {
  const router = useRouter()

  const queryParams = router.query

  const upsertQueryParam = useCallback(
    (key: string, value: string) => {
      router.query[key] = value
      router.replace({ pathname: router.pathname, query: router.query }, undefined, {
        shallow: true,
      })
    },
    [router]
  )

  const removeQueryParam = useCallback(
    (key: string) => {
      delete router.query[key]
      router.replace({ pathname: router.pathname, query: router.query }, undefined, {
        shallow: true,
      })
    },
    [router]
  )

  return { queryParams, upsertQueryParam, removeQueryParam }
}

export { useQueryParams }
