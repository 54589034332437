import { BannerLarge } from '@/miranda'

const ErrorBanner = ({ errorCode }: { errorCode: number }) => {
  return (
    <BannerLarge
      scale="large"
      title={errorCode === 401 ? 'Your credentials are invalid!' : 'We had a problem on our side!'}
      description={
        errorCode === 401 ? 'Please try entering them again' : 'Please try logging in again'
      }
      variant={errorCode === 401 ? 'warning' : 'danger'}
      dismissible={false}
    />
  )
}

export { ErrorBanner }
