import { getEventName } from '@/analytics/getEventName'

const HOME_PAGE_NAME = 'Home'

const HOME_PAGE_ANALYTICS_EVENTS = {
  LOGIN_SUCCESS: getEventName([HOME_PAGE_NAME, 'Login Form'], 'Login Successful'),
  LOGIN_ERROR: getEventName([HOME_PAGE_NAME, 'Login Form'], 'Login Error'),
}

export { HOME_PAGE_ANALYTICS_EVENTS, HOME_PAGE_NAME }
