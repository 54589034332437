import { useContext } from 'react'

import { AuthContext, AuthContextData } from './AuthProvider'

/**
 * Hook to access the User Authentication context
 * @returns {AuthContextData} auth context data { user, isLoggedIn, isLoading, login, logout }
 */
const useAuth = (): AuthContextData => {
  return useContext(AuthContext)
}

export { useAuth }
