import { FormEvent, useCallback, useEffect, useState } from 'react'
import type { NextPage } from 'next'
import Router from 'next/router'

import { Label, Layout, LoadingDots, Spinner, Text, TextField } from '@/miranda'

import { AnalyticsManager } from '@/analytics/AnalyticsManager'
import { useAuth } from '@/auth/useAuth'
import { useEffectOnce } from '@/hooks/useEffectOnce'
import { useQueryParams } from '@/hooks/useQueryParams'

import { ErrorBanner } from './components/Login/ErrorBanner'
import { HOME_PAGE_ANALYTICS_EVENTS, HOME_PAGE_NAME } from './index.analytics'
import {
  LeftColumn,
  LoadsmartLogo,
  LoginForm,
  Main,
  RightColumn,
  SubmitButton,
} from './index.styles'
import { SHIPPER_URL } from './pageUrls'

const Login: NextPage = () => {
  const { login, isLoggedIn, isLoading } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorCode, setErrorCode] = useState()
  const { queryParams } = useQueryParams()

  // Not sure why this was necessary. Tests where failing without it,
  // but behavior couldn´t be reproduced in real world testing
  // May be related to: https://sentry.io/organizations/loadsmart/issues/3389472174/?project=6366523&query=is%3Aunresolved
  const [userWasRedirected, setUserWasRedirected] = useState(false)

  // used to redirect the user after login
  const { redirect } = queryParams as { redirect?: string }

  const shouldAllowSubmit = email && password && !isSubmitting

  const formSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      setSubmitting(true)
      setErrorCode(undefined)
      login(email, password)
        .then(() => {
          AnalyticsManager.track(HOME_PAGE_ANALYTICS_EVENTS.LOGIN_SUCCESS)
        })
        .catch(err => {
          AnalyticsManager.track(HOME_PAGE_ANALYTICS_EVENTS.LOGIN_ERROR)
          setErrorCode(err?.response?.status || 1)
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
    [email, login, password]
  )

  useEffect(() => {
    if (userWasRedirected) return

    if (isLoggedIn) {
      if (redirect) {
        Router.push(redirect)
        setUserWasRedirected(true)
        return
      }

      Router.push(SHIPPER_URL)
      setUserWasRedirected(true)
    }
  }, [redirect, userWasRedirected, isLoggedIn])

  useEffectOnce(() => {
    AnalyticsManager.pageView(HOME_PAGE_NAME)
  })

  return (
    <Main>
      <LeftColumn space="l" justify="space-around">
        <LoadsmartLogo alt="Loadsmart Logo" />
        <Layout.Stack space="l">
          <Text variant="heading-lg" color="color-neutral-white">
            Welcome to the Data Insights portal
          </Text>
          <Text variant="heading-md" color="color-neutral-white">
            Find the information you need to move more with less
          </Text>
        </Layout.Stack>
        <span />
      </LeftColumn>

      <RightColumn>
        {isLoading && <Spinner size={48} />}

        {!isLoading && !isLoggedIn && (
          <LoginForm onSubmit={formSubmit}>
            <Layout.Stack>
              <Text variant="heading-md-bold">Log in to see your data</Text>
              <br />
              {errorCode && (
                <>
                  <ErrorBanner errorCode={errorCode} />
                  <br />
                </>
              )}
              <Layout.Stack space="xs">
                <Label>E-mail</Label>
                <TextField
                  onChange={event => setEmail(event.target.value)}
                  scale="default"
                  value={email}
                  data-testid="email"
                  placeholder="Enter your e-mail address"
                />
              </Layout.Stack>
              <Layout.Stack space="xs">
                <Label>Password</Label>
                <TextField
                  onChange={event => setPassword(event.target.value)}
                  scale="default"
                  value={password}
                  data-testid="password"
                  type="password"
                  placeholder="Enter the password we provided you"
                />
              </Layout.Stack>
              <br />
              <SubmitButton
                variant="primary"
                disabled={!shouldAllowSubmit}
                type="submit"
                isSubmitting={isSubmitting}
              >
                {isSubmitting ? <LoadingDots /> : 'Login'}
              </SubmitButton>
            </Layout.Stack>
          </LoginForm>
        )}
      </RightColumn>
    </Main>
  )
}

export default Login
