import { AnalyticsEventName } from './AnalyticsManager'

/**
 * @param context Array of Contexts strings following the component hierarchy,
 * usually starting with the page. i.e.: `['Home Page', 'Header']`,
 * `['Report Page', 'Cost Chart']`, `['Navbar']`.
 *
 * @param action string representing the user action or interaction we want to track
 * with Analytics. i.e.: `Login`, `Play`, `Expand`
 *
 * @returns AnalyticsEventName following the project event name pattern.
 */
const getEventName = (context: Array<string>, action: string): AnalyticsEventName => {
  return `${context.join(' / ')} / ${action}`
}

export { getEventName }
