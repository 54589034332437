import styled from 'styled-components'

import { Button, ButtonProps, getToken, Layout } from '@/miranda'

import { MEDIA_QUERY_SCREEN } from '@/styles/screen'

const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: stretch;

  ${MEDIA_QUERY_SCREEN.md} {
    height: 100vh;
    flex-direction: row;
  }
`

const LeftColumn = styled(Layout.Stack)`
  flex-basis: 100%;
  padding: ${getToken('space-xl')};
  color: ${getToken('color-neutral-white')};
  position: relative;
  opacity: 1;

  &::after {
    content: '';
    background: url('/assets/images/login_form_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -2;
  }

  &::before {
    content: '';
    background-color: ${getToken('color-neutral-darkest')};
    opacity: 0.77;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  ${MEDIA_QUERY_SCREEN.md} {
    flex-basis: 60%;
  }

  ${MEDIA_QUERY_SCREEN.lg} {
    padding: ${getToken('space-3xl')};
  }

  ${MEDIA_QUERY_SCREEN.xlg} {
    padding: ${getToken('space-3xl')} calc(2 * ${getToken('space-3xl')});
  }
`

const RightColumn = styled(Layout.Stack)`
  flex-basis: 100%;
  justify-content: center;
  padding: ${getToken('space-xl')};

  ${MEDIA_QUERY_SCREEN.md} {
    flex-basis: 40%;
  }

  ${MEDIA_QUERY_SCREEN.lg} {
    padding: ${getToken('space-3xl')};
  }

  ${MEDIA_QUERY_SCREEN.xlg} {
    padding: ${getToken('space-3xl')} calc(2 * ${getToken('space-3xl')});
  }
`

const LoginForm = styled.form``

interface SubmitButtonProps extends ButtonProps {
  isSubmitting: boolean
}

const SubmitButton = styled(Button)<SubmitButtonProps>`
  &:disabled {
    opacity: ${props => (props.isSubmitting ? 1 : 0.4)};
  }
`

const LoadsmartLogo = styled.img`
  content: url('/assets/images/loadsmart_logo.svg');
  width: 150px;
  height: 25px;
  margin-bottom: ${getToken('space-3xl')};
`

export { LeftColumn, LoadsmartLogo, LoginForm, Main, RightColumn, SubmitButton }
